import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de 6 a 7 cm de diàmetre, primer hemisfèric per passar a aplanat, que en temps humit és molt viscós i sec en temps sec. És de color marró, un poc rogenc, més groc cap al marge, enrotllat cap a dins bastant de temps i amb restes de cortina. Les làmines són primes, un poc atapeïdes i de color blanc rogenc que tornen marró amb la caiguda de les espores. Aquestes són d’el·lipsoidals a ovoides, llises, de 6-8 x 3-4 micres. El peu, normalment corbat, d’un color groguenc, més blanquinós a la part superior i més torrat a l’inferior. L’anell pot passar desapercebut i aparèixer amb una mena de restes de cortina que desapareixen aviat deixant només alguns filaments sobre el peu.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      